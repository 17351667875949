// Collapsed Sidebar
@include media-breakpoint-up(lg) {
  .app-sidebar-collapsed {
    .app-sidebar {
      width: $sidebar-width-collapsed;
      position: fixed;
      left: 0;
      //top: 55px;
      bottom: 120px;
      display: flex;
      //height: calc(100% - 120px - 55px);
      &:hover:not(.app-sidebar--mini) {
        width: $sidebar-width;
        position: fixed;
        box-shadow: $sidebar-shadow;
      }
    }
  }
}
