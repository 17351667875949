// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.clear-both {
  display: block;
  clear: both;
  content: "";
}

.scroll-bar {
  //max-height: 500px;
  overflow: auto;
}

.border-right {
  border-right: #DFDFDF solid 1px !important;
  padding-right: 17px;
  height: 33px;
}

.nowrap {
  white-space: nowrap !important;
}

.pt-5px {
  padding-top: 5px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt--4 {
  margin-top: -4px !important;
}

.b-1 {
  border: 1px solid !important;
}

.w-80px {
  width: 80px !important;
}

.w-90px {
  width: 90px !important;
}

.w-100px {
  width: 90px !important;
}

.w-150px {
  width: 150px !important;
}

.w-175px {
  width: 175px !important;
}

.w-210px {
  width: 210px !important;
}

.w-170px {
  width: 175px !important;
}

.m-100px {
  width: 100px !important;
}

.mr-100px {
  margin-right: 100px;
}

.mr-40px {
  margin-right: 40px;
}

.w-85 {
  width: 85% !important;
}

.w-15 {
  width: 15% !important;
}

.background-blue {
  background: rgba(65, 145, 255, 0.15) !important;
}

.background-yellow {
  background: #fdeab2 !important;
}

.p-10-0 {
  padding: 10px 0px;
}

.p-12-0 {
  padding: 12px 0px;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.h-48px {
  height: 48px;
}

.h-36px {
  height: 36px;
}

.mr-10 {
  margin-right: 10px !important;
}

.float-right {
  float: right;
}

.opacity-none {
  opacity: 0 !important;
}

body .MuiButtonBase-root.Mui-disabled {
  opacity: 1;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.top-15 {
  top: -15px !important;
}

.color-red {
  color: red;
}

.color-green {
  color: $green !important;
}

.content-login {
  width: 463px;
}

.point:before {
  content:"• ";
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.app-main input:-webkit-autofill,
.app-main input:-webkit-autofill:hover,
.app-main input:-webkit-autofill:focus,
.app-main input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.w-49 {
  width: 49% !important;
}

.display-none {
  display: none !important;
}

.popper-under {
  z-index: 10;
}

.background-none:not(:hover) {
  background: none !important;
}

.capitalize {
  text-transform: capitalize;
}

.empty-legend legend {
  width: 0px;
}

.p-5 {
  padding: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 0.5rem !important;
}

.grey-border {
  border: 1px solid $contextButtonsBackground !important;
}

.bg-logo {
  background-image: url('/logo.png');
  background-position: center 15px;
  background-repeat: no-repeat;
  background-size: 132px;
  min-width: 140px;
  //min-height: 45px;
  width: 140px;
  height: 100%;
}
.login .MuiLoadingButton-loadingIndicator svg{
  color: #fff !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.code {
  font-size: 16px;
  background: #f1f1f1;
  padding: 4px;
}

.dxc-title text {
  font-weight: 700 !important;
  font-size: .9775rem !important;
  fill: #575859 !important;
}

.bg-none {
  background: none !important;
}

.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content,
.dx-checkbox-icon {
  color: $secondary !important;
}

.dx-datagrid-search-text {
  background: $secondary !important;
  color: #fff;
  padding: 1px;
}

.dx-checkbox.dx-state-hover .dx-checkbox-icon, .dx-texteditor.dx-state-hover{
  border: 1px solid $secondary !important;
}

.dx-textbox {
  //background: #fff9e5 !important;
}

.dx-dropdowneditor-button .dx-button-content {
  background: #fff;
}

.dx-htmleditor-content {
  //background: #fff9e5 !important;
}

.text-box-rounded fieldset,
.text-box-rounded div,
.dx-texteditor.dx-editor-outlined {
  border-radius: 17px !important;;
}

.grid-edit {
  color: $gray-900 !important;
  font-weight: 700 !important;
  text-decoration: none !important;
}

.border {
  border: 1.5px solid $gray !important;
}

.backgound-main {
  background: #f1f1f1;
}

.tree-search {
  border-radius: 4px 4px 0px 0px;

  ::placeholder { color: #49454F !important; }
  ::-webkit-input-placeholder { color: #49454F !important; }
  ::-moz-placeholder { color: #49454F !important; }
}

.card-header-title {
  text-align: left !important;
  margin: 20px 0px 20px 25px;
}

.grid-details input::-webkit-outer-spin-button,
.grid-details input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.grid-details fieldset {
  border-color: whitesmoke;
}
.grid-details label {
  opacity: 0;
}

.grid-details.table thead th {
  height: 36.5px;
}

.grid-details.table thead th, .grid-details.table tbody td {
  background: none;
  border: 1px solid $gray-600 !important;
  //border-top: none;
  padding: 0px;
}

.calculations.table thead th, .calculations.table tbody td {
  padding: 5px;
}

.table thead th, .table tbody td {
  background: none;
  border-bottom: 1px solid $gray !important;
  border-top: none;
  padding: 15px 5px;
}

.table tbody, .table th, .table td {
  border: none !important;
}

.table thead th {
  //font-weight: normal;
  font-size: 14px;
  text-transform: initial;
  color: $green;
}

.notes-popup {
  border: 1px solid $gray !important;
  border-radius: 6px !important;
}

.profile-popup {
  border: 0px !important;
}

.tag {
  margin: 3px !important;
  background: $green !important;
}
.tag .MuiChip-deleteIcon:hover {
  color: #fff !important;
}

.group-charts {
  font-size: 90px;
  margin-top: 22px;
  text-align: center;
}

.checkbox-label span {
  font-weight: 700 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiSwitch-root .Mui-checked+.MuiSwitch-track {
  background-color: $green !important;
}

.btn-outline-none {
  outline: none !important;
}

.margin-none {
  margin: 0px !important;
}

.privacy-policy h3 {
  margin-bottom: 70px;
}

.privacy-policy h5 {
  margin-bottom: 20px;
}

.privacy-policy h6 {
  margin: 20px 0px 10px;
  color: $green;
}

.privacy-policy .MuiTypography-gutterBottom {
  margin-bottom: 15px;
}

.c-json-key {
  color: $first !important;
  font-weight: bold;
}

.c-json-string, .c-json-null {
  color: $green !important;
  font-weight: bold;
}

.approvals .dxl-marker,
.approvals .dxl-marker~text {
  cursor: pointer;
}
.approvals .dxc-markers,
.approvals .dxc-labels g,
.approvals .dxc-labels rect,
.approvals .dxc-labels text {
  cursor: pointer !important;
}

.modal-confirm {
  width: 400px;
}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor:pointer;
}

/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $green;
  border-radius: 17px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $green;
}

@include media-breakpoint-up(lg) {
  .top-content {
    padding-top: $header-height + 50 !important;
  }
}
